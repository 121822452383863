@use "sass:math";
$headerHeight: 5rem;

// Colors
$color-white: #ffffff;
$color-greyLight: #EEEEF5;
$color-grey: #CECFE2;
$color-black: #1e1e1e;
$color-primary: #484D97;
$color-primaryHover: #24349E;
$color-primaryLight: #5E6CC9;
$color-primaryDark: #14193B;
$color-secondary: #d69e34;

// Mixins
@mixin titleFont {
    font-family: "Merriweather", serif;
}

@mixin bodyFont {
    font-family: "Libre Franklin", sans-serif;
}

@mixin bodyText {
    font-size: 1.2rem;
    line-height: 1.75;
}

@mixin backgroundLight {
    background-color: $color-white;
    color: $color-black;
}

@mixin backgroundDark {
    background-color: $color-primaryDark;
    color: rgba($color-white, 0.75);

    & a.link:hover {
        color: rgba($color-white, 0.9);
    }
}

* {
    box-sizing: border-box;
}

html,
body {
    @include bodyFont;
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    color: $color-black;
}

main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    // Make the bottom section expand
    section:last-of-type {
        flex-grow: 1;
    }
}

footer {
    width: 100%;
    @include backgroundDark;
}

hr {
    width: 100%;
    border-color: currentColor;
    opacity: 0.5;
    border-top: none;
}

.separator {
    user-select: none;
    text-align: center;
    height: 0;
    position: relative;

    &::after {
        content: '***';
        @include titleFont();
        font-size: 1.25rem;
        color: $color-secondary;
        position: absolute;
        top: -0.5rem;
        opacity: 0.75;
        letter-spacing: 0.5rem;
        left: 50%;
        transform: translate(-50%);
    }
}

// Typography
h1,
h2,
h3,
h4 {
    @include titleFont;
    letter-spacing: 0.05rem;
}

h5,
h6 {
    @include bodyText;
    font-weight: 600;
    margin: 0.5rem;
}

h1 {
    margin: 2rem 0;
    font-size: 3.7rem;

    &:after {
        content: "";
        display: block;
        width: 4rem;
        height: 0.5rem;
        border-radius: 2px;
        background-color: $color-secondary;
    }
}

h2 {
    margin: 1rem 0;
    font-size: 2rem;
}

h3 {
    margin: 0.75rem 0;
    font-size: 1.5rem;
}

h4 {
    margin: 0.75rem 0;
    font-size: 1.25rem;
}

i {
    font-style: italic;
}

p {
    @include bodyText;
    @include bodyFont;
}

a {
    color: currentColor;
    text-decoration: none;

    &.link {
        text-decoration: underline;

        &:hover {
            color: $color-primaryHover;
        }
    }

    &.linkSubtle:hover {
        text-decoration: underline;
        color: currentColor;
    }

    &:visited {
        color: currentColor;
    }
}

a.button {
    @include titleFont;
    background-color: $color-primary;
    color: $color-white;
    transition: background-color 333ms;
    display: inline-flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;

    &:hover {
        background-color: $color-primaryHover;
    }
}

figure {
    padding: 0;
    margin: 0;

    blockquote {
        @extend i;
        @include bodyText;
        margin: 0;

        &::before {
            content: "\201C";
        }

        &::after {
            content: "\201D";
        }
    }

    figcaption {
        @extend p;
        @include bodyText;
        text-align: right;
        margin-top: 1rem;

        &::before {
            content: "— ";
        }
    }
}

// Page
.baseContainer {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 4rem;
    padding-right: 4rem;
}

.hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    & .section {
        padding-top: 5rem;
        padding-bottom: 5rem;

        & h1 {
            color: $color-white;
        }
    }
}

.imgBgr {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.section {
    @extend .baseContainer;
    margin-top: 2rem;
    margin-bottom: 2rem;

    section:first-of-type & {
        margin-top: 4rem;
    }

    section:last-of-type & {
        margin-bottom: 4rem;
    }
}

.splitColsSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    &.centerY {
        align-items: center;
    }
}

img.cover {
    height: 400px;
    object-fit: cover;
    width: 100%;
    border-radius: 2px;
    background-color: $color-greyLight;
}

.map {
    height: 400px;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
    background-color: $color-greyLight;
}

.pdfCard {
    border: 2px solid $color-primaryDark;
    border-radius: 2px;
    padding: 1rem;
    display: grid;
    grid-template-columns: 2rem auto 2rem;
    grid-column-gap: 1rem;
    height: 100%;
    align-items: center;
    cursor: pointer;
    transition: border-color 333ms;

    &:hover {
        border-color: $color-primaryHover;

        & .pdfTitle {
            color: $color-primaryHover;
        }

        & .pdfIcon,
        & .pdfDownloadIcon {
            color: $color-primaryHover;
        }
    }

    & .pdfIcon,
    & .pdfDownloadIcon {
        width: 100%;
        transition: color 333ms;
        color: $color-primaryDark;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .pdfTitle {
        transition: color 333ms;
        font-size: 1.2rem;
    }

    & svg {
        width: 100%;
    }
}

// Header nav
.headerSpacer {
    display: block;
    height: $headerHeight;
}

.headerBackdrop {
    height: $headerHeight + 2rem;
    width: 100%;
    position: fixed;
    overflow: hidden;

    & .headerShadow {
        height: $headerHeight;
        position: absolute;
        z-index: 9;
        width: calc(100% + 2rem);
        left: -1rem;
        top: 0;
        opacity: 0;
        clip-path: polygon(0 0, 100% 0, 100% 150%, 0% 150%);
        box-shadow: 0 -2px 15px rgba($color-primaryDark, 0.3);
        transition: opacity 0.3s ease-in-out;
    }

    &.sticky .headerShadow {
        opacity: 1;
    }
}

header {
    background-color: $color-white;
    height: $headerHeight;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
}

.header {
    @extend .baseContainer;
    height: $headerHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>a {
        line-height: 0;
    }
}

.headerLogo {
    height: 3.75rem;
    transform-origin: left;
    transition: transform 333ms;

    .sticky+header & {
        transform: scale(0.8);
    }
}

.nav {
    text-align: right;
    display: block;
    margin: 0 -0.25rem;
}

.navItem {
    @include bodyText;
    padding: 0.5rem 0.25rem;
    margin-right: 1.5rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 4px;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: $color-primary;
        opacity: 0;
        transition-duration: 333ms;
        transition-timing-function: ease-out;
        transition-property: transform, opacity;
        transform: scaleX(0.5);
    }

    &:not(.button):hover::after {
        opacity: 1;
        transform: scaleX(1);
        transition-timing-function: ease;
    }

    &:last-child {
        margin-right: 0;
    }
}

#navToggle,
.navMobileToggle {
    display: none;
}

.footerContainer {
    @extend .baseContainer;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
    align-items: stretch;
    row-gap: 1rem;
}

.smallGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 0.75rem;
    align-items: flex-start;
}

.tinyGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 0.75rem;
    align-items: flex-start;
}

.footerBlurb {
    opacity: 0.5;
}

.footerLogo {
    height: 2.75rem;
    opacity: 0.5;
}

@mixin baseContainerMobile {
    max-width: 560px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

@media screen and (max-width: 900px) {

    // Nav
    .navMobileToggle {
        display: block;
    }

    .headerLogo {
        transform: scale(1) !important;
    }

    .nav {
        background-color: $color-white;
        position: absolute;
        width: 100%;
        left: 0;
        margin: 0;
        top: $headerHeight;

        .navItemsShadow {
            top: 0;
            height: 100%;
            position: absolute;
            z-index: 9;
            width: 100%;
            clip-path: polygon(0 0, 100% 0, 100% 150%, 0% 150%);
            box-shadow: 0 -2px 15px rgba($color-primaryDark, 0.3);
        }

        .navItems {
            @include baseContainerMobile;
            position: relative;
            z-index: 10;
            margin: 0 auto;
            display: grid;
            grid-template-rows: 1fr;
            row-gap: 0.25rem;
            justify-items: end;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        .navItem {
            margin: 0;

            &::after {
                transform-origin: right;
            }
        }
    }

    $toggleBarHeight: 3px;
    $toggleBarWidth: 2rem;
    $toggleBarDistance: 0.75rem;

    .navMobileToggle {
        width: calc($toggleBarWidth + 1rem);
        height: calc($toggleBarWidth + 1rem);
        padding: 0.5rem;
        margin-right: -0.5rem;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .toggle {
        width: 100%;
        position: relative;

        & .a,
        & .b,
        & .c {
            transition: transform 333ms;
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            top: -#{math.div($toggleBarHeight, 2)};
            height: $toggleBarHeight;
            background-color: $color-primaryDark;
            border-radius: 1px;
        }
    }

    #navToggle {
        display: none;

        &+label .toggle {
            & .a {
                transform: translateY(calc(-#{$toggleBarDistance} + #{math.div($toggleBarHeight, 2)}));
            }

            & .b {
                transform: translateY(0);
            }

            & .c {
                transform: translateY(calc(#{$toggleBarDistance} - #{math.div($toggleBarHeight, 2)}));
            }
        }

        &+label+.nav {
            opacity: 0;
            transform: translateY(-0.5rem);
            pointer-events: none;
            transition: opacity, transform;
            transition-duration: 333ms;
        }

        &:checked {
            &+label .toggle {
                & .a {
                    transform: translateY(0) rotate(-45deg);
                }

                & .b {
                    transform: translateY(0) scaleX(0);
                }

                & .c {
                    transform: translateY(0) rotate(45deg);
                }
            }

            &+label+.nav {
                opacity: 1;
                transform: translateY(0);
                pointer-events: all;
            }
        }
    }

    .splitColsSection {
        grid-template-columns: 1fr;
    }

    .baseContainer {
        @include baseContainerMobile;
    }

    .hero .section {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }

    .section {
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-top: 0;
        margin-bottom: 0;

        section:first-of-type & {
            margin-top: 1.5rem;
        }

        section:last-of-type & {
            margin-bottom: 1.5rem;
        }
    }

    .imgBgr {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    img.cover:not(.profile) {
        height: 280px;
    }
}