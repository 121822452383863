* {
  box-sizing: border-box;
}

html, body {
  color: #1e1e1e;
  margin: 0;
  font-family: Libre Franklin, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

main {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

main section:last-of-type {
  flex-grow: 1;
}

footer {
  color: #ffffffbf;
  background-color: #14193b;
  width: 100%;
}

footer a.link:hover {
  color: #ffffffe6;
}

hr {
  opacity: .5;
  border-color: currentColor;
  border-top-style: none;
  border-top-width: medium;
  width: 100%;
}

.separator {
  user-select: none;
  text-align: center;
  height: 0;
  position: relative;
}

.separator:after {
  content: "***";
  color: #d69e34;
  opacity: .75;
  letter-spacing: .5rem;
  font-family: Merriweather, serif;
  font-size: 1.25rem;
  position: absolute;
  top: -.5rem;
  left: 50%;
  transform: translate(-50%);
}

h1, h2, h3, h4 {
  letter-spacing: .05rem;
  font-family: Merriweather, serif;
}

h5, h6 {
  margin: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.75;
}

h1 {
  margin: 2rem 0;
  font-size: 3.7rem;
}

h1:after {
  content: "";
  background-color: #d69e34;
  border-radius: 2px;
  width: 4rem;
  height: .5rem;
  display: block;
}

h2 {
  margin: 1rem 0;
  font-size: 2rem;
}

h3 {
  margin: .75rem 0;
  font-size: 1.5rem;
}

h4 {
  margin: .75rem 0;
  font-size: 1.25rem;
}

i, figure blockquote {
  font-style: italic;
}

p, figure figcaption {
  font-family: Libre Franklin, sans-serif;
  font-size: 1.2rem;
  line-height: 1.75;
}

a {
  color: currentColor;
  text-decoration: none;
}

a.link {
  text-decoration: underline;
}

a.link:hover {
  color: #24349e;
}

a.linkSubtle:hover {
  color: currentColor;
  text-decoration: underline;
}

a:visited {
  color: currentColor;
}

a.button {
  color: #fff;
  background-color: #484d97;
  border-radius: .25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .75rem 1rem;
  font-family: Merriweather, serif;
  font-weight: 700;
  transition: background-color .333s;
  display: inline-flex;
}

a.button:hover {
  background-color: #24349e;
}

figure {
  margin: 0;
  padding: 0;
}

figure blockquote {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.75;
}

figure blockquote:before {
  content: "“";
}

figure blockquote:after {
  content: "”";
}

figure figcaption {
  text-align: right;
  margin-top: 1rem;
  font-size: 1.2rem;
  line-height: 1.75;
}

figure figcaption:before {
  content: "— ";
}

.baseContainer, .footerContainer, .header, .section {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}

.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero .section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.hero .section h1 {
  color: #fff;
}

.imgBgr {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.section {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

section:first-of-type .section {
  margin-top: 4rem;
}

section:last-of-type .section {
  margin-bottom: 4rem;
}

.splitColsSection {
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.splitColsSection.centerY {
  align-items: center;
}

img.cover {
  object-fit: cover;
  background-color: #eeeef5;
  border-radius: 2px;
  width: 100%;
  height: 400px;
}

.map {
  background-color: #eeeef5;
  border-radius: 2px;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.pdfCard {
  grid-column-gap: 1rem;
  cursor: pointer;
  border: 2px solid #14193b;
  border-radius: 2px;
  grid-template-columns: 2rem auto 2rem;
  align-items: center;
  height: 100%;
  padding: 1rem;
  transition: border-color .333s;
  display: grid;
}

.pdfCard:hover {
  border-color: #24349e;
}

.pdfCard:hover .pdfTitle, .pdfCard:hover .pdfIcon, .pdfCard:hover .pdfDownloadIcon {
  color: #24349e;
}

.pdfCard .pdfIcon, .pdfCard .pdfDownloadIcon {
  color: #14193b;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: color .333s;
  display: flex;
}

.pdfCard .pdfTitle {
  font-size: 1.2rem;
  transition: color .333s;
}

.pdfCard svg {
  width: 100%;
}

.headerSpacer {
  height: 5rem;
  display: block;
}

.headerBackdrop {
  width: 100%;
  height: 7rem;
  position: fixed;
  overflow: hidden;
}

.headerBackdrop .headerShadow {
  z-index: 9;
  opacity: 0;
  clip-path: polygon(0 0, 100% 0, 100% 150%, 0% 150%);
  width: calc(100% + 2rem);
  height: 5rem;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: -1rem;
  box-shadow: 0 -2px 15px #14193b4d;
}

.headerBackdrop.sticky .headerShadow {
  opacity: 1;
}

header {
  z-index: 10;
  background-color: #fff;
  width: 100%;
  height: 5rem;
  position: fixed;
  top: 0;
}

.header {
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  display: flex;
}

.header > a {
  line-height: 0;
}

.headerLogo {
  transform-origin: 0;
  height: 3.75rem;
  transition: transform .333s;
}

.sticky + header .headerLogo {
  transform: scale(.8);
}

.nav {
  text-align: right;
  margin: 0 -.25rem;
  display: block;
}

.navItem {
  margin-right: 1.5rem;
  padding: .5rem .25rem;
  font-size: 1.2rem;
  line-height: 1.75;
  position: relative;
}

.navItem:after {
  content: "";
  opacity: 0;
  background-color: #484d97;
  border-radius: 2px;
  width: 100%;
  height: 2px;
  transition-property: transform, opacity;
  transition-duration: .333s;
  transition-timing-function: ease-out;
  position: absolute;
  bottom: 4px;
  left: 0;
  transform: scaleX(.5);
}

.navItem:not(.button):hover:after {
  opacity: 1;
  transition-timing-function: ease;
  transform: scaleX(1);
}

.navItem:last-child {
  margin-right: 0;
}

#navToggle, .navMobileToggle {
  display: none;
}

.footerContainer {
  align-items: stretch;
  row-gap: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: grid;
}

.smallGrid {
  grid-gap: .75rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  align-items: flex-start;
  display: grid;
}

.tinyGrid {
  grid-gap: .75rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  align-items: flex-start;
  display: grid;
}

.footerBlurb {
  opacity: .5;
}

.footerLogo {
  opacity: .5;
  height: 2.75rem;
}

@media screen and (width <= 900px) {
  .navMobileToggle {
    display: block;
  }

  .headerLogo {
    transform: scale(1) !important;
  }

  .nav {
    background-color: #fff;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 5rem;
    left: 0;
  }

  .nav .navItemsShadow {
    z-index: 9;
    clip-path: polygon(0 0, 100% 0, 100% 150%, 0% 150%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-shadow: 0 -2px 15px #14193b4d;
  }

  .nav .navItems {
    z-index: 10;
    grid-template-rows: 1fr;
    justify-items: end;
    row-gap: .25rem;
    max-width: 560px;
    margin: 0 auto;
    padding: 1.5rem 1.25rem;
    display: grid;
    position: relative;
  }

  .nav .navItem {
    margin: 0;
  }

  .nav .navItem:after {
    transform-origin: 100%;
  }

  .navMobileToggle {
    cursor: pointer;
    align-items: center;
    width: 3rem;
    height: 3rem;
    margin-right: -.5rem;
    padding: .5rem;
    display: flex;
  }

  .toggle {
    width: 100%;
    position: relative;
  }

  .toggle .a, .toggle .b, .toggle .c {
    background-color: #14193b;
    border-radius: 1px;
    width: 100%;
    height: 3px;
    transition: transform .333s;
    display: block;
    position: absolute;
    top: -1.5px;
    left: 0;
  }

  #navToggle {
    display: none;
  }

  #navToggle + label .toggle .a {
    transform: translateY(calc(1.5px - .75rem));
  }

  #navToggle + label .toggle .b {
    transform: translateY(0);
  }

  #navToggle + label .toggle .c {
    transform: translateY(calc(.75rem - 1.5px));
  }

  #navToggle + label + .nav {
    opacity: 0;
    pointer-events: none;
    transition: opacity .333s, transform .333s;
    transform: translateY(-.5rem);
  }

  #navToggle:checked + label .toggle .a {
    transform: translateY(0)rotate(-45deg);
  }

  #navToggle:checked + label .toggle .b {
    transform: translateY(0)scaleX(0);
  }

  #navToggle:checked + label .toggle .c {
    transform: translateY(0)rotate(45deg);
  }

  #navToggle:checked + label + .nav {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  .splitColsSection {
    grid-template-columns: 1fr;
  }

  .baseContainer, .section, .header, .footerContainer {
    max-width: 560px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .hero .section {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .section {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  section:first-of-type .section {
    margin-top: 1.5rem;
  }

  section:last-of-type .section {
    margin-bottom: 1.5rem;
  }

  .imgBgr {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  img.cover:not(.profile) {
    height: 280px;
  }
}

/*# sourceMappingURL=about.0ced4b46.css.map */
